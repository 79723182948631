<!-- 运营端和代理商端通用 代理商业绩统计 -->
<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据中心</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{userJson.roleId == '1'?'代理商业绩统计':'班级结算统计'}} </a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="代理商名称" v-if="userJson.roleId == '1'" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">代理商名称:</span>
              <el-select
                size="small"
                v-model="searchData.agencyId"
                remote
                :remote-method="getAgencyName"
                filterable
                clearable
                placeholder="请输入要查询的代理商"
              >
                <el-option
                  v-for="item in agencyNameList"
                  :key="item.agencyId"
                  :label="item.agencyName"
                  :value="item.agencyId"
                ></el-option>
              </el-select>
            </div>
            <div title="企业名称" class="searchboxItem ci-full">
              <span class="itemLabel">企业名称:</span>
              <el-select size="small" v-model="searchData.compId" remote :remote-method="getCompanyList" filterable
                clearable placeholder="请至少输入两个字搜索" class="width_w">
                <el-option v-for="item in companyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input v-model.trim="searchData.projectCode" clearable placeholder="请输入班级编码" size="small"></el-input>
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input v-model.trim="searchData.projectName" clearable placeholder="请输入班级名称" size="small"></el-input>
            </div>
            <div title="是否结清" class="searchboxItem ci-full">
              <span class="itemLabel">是否结清:</span>
              <el-select size="small" v-model="searchData.settleState" placeholder="请选择是否结清" clearable>
                <el-option label="已结清" :value="true"></el-option>
                <el-option label="未结清" :value="false"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="开始培训时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 8em;">开始培训时间:</span>
              <el-date-picker clearable size="small" v-model="searchData.startDate" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div title="结束培训时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 8em;">结束培训时间:</span>
              <el-date-picker clearable size="small" v-model="searchData.endDate" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div class="df" style="padding-left:10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="exportData()">导出</el-button>
            </div>
          </div>
        </div>
        <div class="statistics">
          <span>入班人数: {{ statisticsInfo.currentNumTotal }} 人</span>
          <span>认证人数: {{ statisticsInfo.realNameNumTotal }} 人</span>
          <span>班级学习人数: {{ statisticsInfo.studyNumTotal }} 人</span>
          <span>完成学习人数: {{ statisticsInfo.completeNumTotal }} 人</span>
          <span>结业人数: {{ statisticsInfo.graduateNumTotal }} 人</span>
          <span>企业结算金额: {{ statisticsInfo.platformSettlementAmountTotal }} 元</span>
          <span>应付拓展服务费: {{ statisticsInfo.agencyActualFeeTotal }} 元</span>
          <span>已付拓展服务费: {{ statisticsInfo.paidFeeTotal }} 元</span>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column v-if="userJson.roleId == '1'" label="代理商名称" align="left" show-overflow-tooltip prop="agencyName" min-width="200" />
              <el-table-column label="企业名称" align="left" show-overflow-tooltip prop="compName" min-width="260" />
              <el-table-column label="班级编码" align="left" show-overflow-tooltip prop="projectCode" min-width="180" />
              <el-table-column label="班级名称" align="left" show-overflow-tooltip prop="projectName" min-width="180" />
              <el-table-column label="区县" align="left" show-overflow-tooltip prop="areaNamePath" min-width="180" />
              <el-table-column label="开始培训时间" align="left" show-overflow-tooltip prop="startDate" min-width="100">
                <template slot-scope="scope">
                  {{ scope.row.startDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column label="结束培训时间" align="left" show-overflow-tooltip prop="endDate" min-width="100">
                <template slot-scope="scope">
                  {{ scope.row.endDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column label="入班人数" align="left" show-overflow-tooltip prop="currentNum" min-width="80" />
              <el-table-column label="认证人数" align="left" show-overflow-tooltip prop="realNameNum" min-width="80" />
              <el-table-column label="班级学习人数" align="left" show-overflow-tooltip prop="studyNum" min-width="100" />
              <el-table-column label="完成学习人数" align="left" show-overflow-tooltip prop="completeNum" min-width="100" />
              <el-table-column label="结业人数" align="left" show-overflow-tooltip prop="graduateNum" min-width="80" />
              <el-table-column label="企业结算金额" align="left" show-overflow-tooltip prop="platformSettlementAmount" min-width="100" />
              <el-table-column label="实收金额" align="left" show-overflow-tooltip prop="actualReceivedFee" min-width="100" />
              <el-table-column label="到账日期" align="left" show-overflow-tooltip prop="payeeDate" min-width="100" >
              <template slot-scope="scope">
                  {{ scope.row.payeeDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column label="是否结清" align="left" show-overflow-tooltip prop="settleState" min-width="100">
              <template slot-scope="scope">
                  {{ scope.row.settleState?'已结清':'未结清' }}
                </template>
              </el-table-column>
              <el-table-column label="欠款额" align="left" show-overflow-tooltip prop="outstandingAmount" min-width="100" />
              <el-table-column label="开票金额" align="left" show-overflow-tooltip prop="invoiceFee" min-width="100" />
              <el-table-column label="应付拓展服务费" align="left" show-overflow-tooltip prop="agencyActualFee" min-width="110" />
              <el-table-column label="已付拓展服务费" align="left" show-overflow-tooltip prop="paidFee" min-width="110" />
              <el-table-column label="操作" align="center" width="190px" fixed="right">
                <div slot-scope="scope">
                  <el-button type="text" size="mini" @click="examine(1,scope.row)">查看收款单</el-button>
                  <el-button type="text" size="mini" @click="examine(2,scope.row)">查看付款单</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
     <!-- 查看收款单查看付款单 -->
     <el-dialog
      :title="`查看${dialogData.dialogType===1?'收款':'付款'}单`"
      :visible.sync="dialogData.dialogState"
      v-if="dialogData.dialogState"
      width="800px"
      center
    >
      <div>
        <div style="margin: 15px 0;">
          <span>企业名称: {{ dialogData.compName }}</span>&ensp;&ensp;
          <span>班级编码: {{ dialogData.projectCode }}</span>&ensp;&ensp;
          <span>班级名称: {{ dialogData.projectName }}</span>
        </div>
        <el-table :data="dialogData.tableData" height="550px" size="small" tooltip-effect="dark"
          style="width: 100%" :header-cell-style="tableHeader" stripe>
            <el-table-column label="序号" align="center" type="index" />
            <!-- 收款 -->
            <template v-if="dialogData.dialogType===1">
              <el-table-column label="结算单号" align="left"  prop="billNo" min-width="150" />
              <el-table-column label="结算人数" align="left"  prop="settleNum" min-width="110" />
              <el-table-column label="平台结算金额" align="left"  prop="platformSettlementAmount" min-width="110" />
              <el-table-column label="到账金额" align="left"  prop="actualReceivedFee" min-width="110" />
              <el-table-column label="到账日期" align="left"  prop="payeeDate" min-width="110" />
              <el-table-column label="开票金额" align="left"  prop="invoiceFee" min-width="110" />
              <el-table-column label="开票日期" align="left"  prop="invoiceDate" min-width="110" />
              <el-table-column label="票据类型" align="left"  prop="invoiceType" min-width="110" >
              <template slot-scope="scope">
                  <span>
                    {{ $setDictionary("INVOICE_TYPE", scope.row.invoiceType) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="更新日期" align="left"  prop="updateTime" min-width="150" />
            </template>
            <!-- 付款 -->
            <template v-if="dialogData.dialogType===2">
              <el-table-column v-if="userJson.roleId == '1'" label="代理商" align="left"  prop="agencyName" min-width="110" />
              <el-table-column label="收款结算单号" align="left"  prop="billNo" min-width="150" />
              <el-table-column label="收款结算人数" align="left"  prop="settleNum" min-width="110" />
              <el-table-column label="收款结算金额" align="left"  prop="platformSettlementAmount" min-width="110" />
              <el-table-column label="到账金额" align="left"  prop="actualReceivedFee" min-width="110" />
              <el-table-column label="到账日期" align="left"  prop="payeeDate" min-width="110" />
              <el-table-column label="代理结算单号" align="left"  prop="agencyBillNo" min-width="150" />
              <el-table-column label="应付拓展服务费" align="left"  prop="agencyActualFee" min-width="110" />
              <el-table-column label="已付拓展服务费" align="left"  prop="paidFee" min-width="110" />
              <el-table-column label="付款日期" align="left"  prop="paidDate" min-width="110" />
              <el-table-column label="更新日期" align="left"  prop="updateTime" min-width="150" />
            </template>
          <Empty slot="empty" />
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "classSettlementStatistics",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  //页面初始化时不自动查询列表数据
  // doNotInit: true,
  data () {
    return {
      //查询条件
      searchData: {
        projectCode: "",
        projectName: "",
        agencyId: "",
        compId: "",
        // 开始培训时间
        startDate: [],
        // 结束培训时间
        endDate: [],
        // 是否结清
        settleState: undefined,
      },
      // 统计信息
      statisticsInfo:{
        currentNumTotal: "--",
        realNameNumTotal: "--",
        studyNumTotal: "--",
        completeNumTotal: "--",
        graduateNumTotal: "--",
        platformSettlementAmountTotal: "--",
        agencyActualFeeTotal: "--",
        paidFeeTotal: "--",
      },
      // 代理商 - 下拉数据
      agencyNameList: [],
      // 企业 - 下拉数据
      companyList: [],
      dialogData:{
        dialogType:1,
        dialogState:false,
        compName:'',
        projectCode:'',
        projectName:'',
        tableData:[]
      },
    };
  },
  watch: {},
  created () { },
  computed: {
    ...mapGetters({
      userJson: "getUser",
      downloadItems: "getDownloadItems",
    }),
  },
  mounted () {
    this.getTableHeight();
  },
  methods: {
    async getData (pageNum = 1) {
      const {projectCode,agencyId,compId,projectName,settleState,startDate,endDate} = this.searchData
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectCode: projectCode??undefined,
        projectName: projectName??undefined,
        agencyId: agencyId??undefined,
        compId: compId??undefined,
        settleState: settleState??undefined,
      };
      if (startDate?.length>0) {
        params.startDateStart = startDate[0];
        params.startDateEnd = startDate[1];
      }
      if (endDate?.length>0) {
        params.endDateStart = endDate[0];
        params.endDateEnd = endDate[1];
      }
      await this.$post("/biz/new/bill/project/agencyStatementCount", params)
      .then((res) => {
        Object.keys(this.statisticsInfo).forEach(key=>{
          this.$set(this.statisticsInfo,key,res.data[key])??this.$set(this.statisticsInfo,key,'--')
        })
      })
      this.doFetch({
          url: "/biz/new/bill/project/agencyStatementList",
          params,
          pageNum,
        });
    },
    exportData () {
      const {projectCode,projectName,settleState,agencyId,compId,startDate,endDate} = this.searchData
      const params = {
        projectCode: projectCode??undefined,
        projectName: projectName??undefined,
        agencyId: agencyId??undefined,
        compId: compId??undefined,
        settleState: settleState,
      };
      if (startDate?.length>0) {
        params.startDateStart = startDate[0];
        params.startDateEnd = startDate[1];
      }
      if (endDate?.length>0) {
        params.endDateStart = endDate[0];
        params.endDateEnd = endDate[1];
      }
      this.$post("/biz/new/bill/project/agencyPerformanceStatementExport", params)
        .then((res) => {
          if (res.status == "0") {
              const arr=[...res.data];
              for (let item of arr) {
                if (!this.downloadItems.includes(item.taskId)) {
                  this.$store.dispatch("pushDownloadItems", item.taskId);
                } else {
                  this.$message.warning(
                      "[" + item.fileName + "]已经申请下载,请耐心等待"
                  );
                }
              }
            } else {
              this.$message.error(res.message);
            }
        })
        .catch((err) => {
          return;
        });
    },
    // 获取 - 企业名称
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    // 根据关键字获取代理商名称
    getAgencyName(query) {
      this.$post("/biz/mercantile/agency/searchList", {
        agencyName: query,
        agencyCooperationType: 20, 
      })
      .then((res) => {
        if (res.status == 0) {
          this.agencyNameList = res.data || [];
        }
      })
      .catch(() => {
        return;
      });
    },
    // 查看
    async examine(type,row){
      this.dialogData.dialogType = type
      this.dialogData.compName = row.compName
      this.dialogData.projectCode = row.projectCode
      this.dialogData.projectName = row.projectName
      let url = ''
      if(type===1){
        url = '/biz/new/bill/project/getProjectBill'
      }else if(type===2){
        url = '/biz/new/bill/project/getProjectAgencyBill'
      }
      await this.$post(url, {projectId:row.projectId}).then((res) => {
        this.dialogData.tableData = res.data
        this.dialogData.dialogState = true
      })
      .catch(() => {
        return;
      });
    }
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.statistics{
  margin: 5px 15px 0;
  span+span{
    margin-left: 15px;
  }
}
</style>

